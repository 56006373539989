import { TeamMemberTypes } from './tables/team';

export namespace PortalServiceTypes {
  export interface LoginPayload {
    email: string;
    password: string;
  }

  export enum ChallengeName {
    MFA_SETUP = 'MFA_SETUP',
    SOFTWARE_TOKEN_MFA = 'SOFTWARE_TOKEN_MFA',
    NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
    PASSWORD_RESET_REQUESTED = 'PASSWORD_RESET_REQUESTED',
  }
  export enum Role {
    ADMIN = 'admin',
    VIEW = 'view',
    TREASURY = 'treasury',
  }

  export type RolePermissions = {
    [key in Role]: string[];
  };

  export interface User {
    id: string;
    name: string;
    email: string;
    challengeName?: string;
    'custom:role': string;
    'custom:partnerId': string;
    token: string;
    secret?: string;
    status?: string;
    partnerId: string;
    role: Role;
  }

  export interface CreateIPAddressRequest {
    ipAddress: string;
  }

  export interface UpdateIPAddressRequest {
    ipAddress: string;
    status: string;
  }

  export interface GetIPAddressesResponse {
    partnerId: string;
    ipAddresses: string[];
  }

  export interface CreateWalletRequest {
    address: string;
    network: string;
    tag?: string;
    currencies: string[];
  }

  export interface DeleteWalletResponse {
    deleted: boolean;
  }

  export interface VerifyOtpResponse {
    isValid: boolean;
  }

  export interface DeleteWalletRequest {
    deleteReason: string;
    token: string;
  }

  export interface LoginResponse {
    token: string;
    challengeName: ChallengeName;
    user?: User;
    refreshToken: string;
    session?: string;
    mfaSecret?: string;
    userName: string;
  }

  export interface SetPasswordPayload {
    password: string;
    email?: string;
    tempPassword: string;
  }

  export interface SetUserSecretPayload {
    secret: string;
  }

  export interface RespondToAuthChallengeResponse {
    token: string;
    user?: User;
    refreshToken: string;
  }

  export interface VerifySoftwareTokenPayload {
    session: string;
    userCode: string;
    username: string;
  }

  export interface VerifySoftwareTokenResponse {
    session: string;
    status: string;
    challengeResponse: RespondToAuthChallengeResponse;
  }

  export interface RespondToAuthChallengePayload {
    session: string;
    challengeName: ChallengeName;
    username: string;
    userCode?: string;
  }

  export interface RefreshAccessTokenPayload {
    refreshToken: string;
  }

  export interface RefreshAccessTokenResponse {
    accessToken: string;
    expiry: number;
  }

  export interface ApiKey {
    partnerId: string;
    apiKey: string;
    status: string;
    secretKey: string;
    name: string;
    permissions: string[];
    expiry?: string;
    createdAt: string;
    updatedAt: string;
  }

  export interface CognitoUser extends User {
    sub: string;
    iss: string;
    'cognito:username': string;
    origin_jti: string;
    aud: string;
    event_id: string;
    token_use: string;
    auth_time: string;
    exp: number;
    ait: string;
    jti: string;
  }

  export interface TemporaryUserLogin {
    email: string;
    challengeName: string;
  }

  export enum AccountNumberType {
    PHONE = 'phone',
    BANK = 'bank',
    APP = 'app',
  }

  export enum Status {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
  }

  export enum balancePayoutType {
    MANUAL = 'MANUAL',
    AUTOMATED = 'AUTOMATED',
  }

  export enum balancePayoutFrequency {
    DAILY = 'Daily',
    WEEKLY = 'Weekly',
    MONTHLY = 'Monthly',
    YEARLY = 'Yearly',
  }

  export enum balancePayoutEndType {
    NEVER = 'Never',
    ON_DATE = 'On',
    AFTER_PAYOUTS = 'After Payouts',
  }

  export type Address = {
    id: string;
    cryptocurrency: string;
    network: string;
    address: string;
    status: string;
    tag?: string;
  };

  export interface PartnerWallet {
    id: string;
    partnerId: string;
    address: string;
    network: string;
    status: string;
    tag?: string;
    currencies: string[];
  }

  export interface Partner {
    id: string;
    name: string;
    abbreviation: string;
    walletAddress?: string;
    withdrawalAddress?: string;
    settlementThreshold: number;
    lowBalanceLimit: number;
    payoutType?: balancePayoutType;
    payoutFrequency?: balancePayoutFrequency;
  }

  export interface annualPayoutsDate {
    day: number;
    month: string;
  }

  export interface PayoutConfigRequest {
    type: balancePayoutType;
    frequency?: balancePayoutFrequency;
    repeatFrequency?: number;
    weeklyDays?: string[];
    yearlyDate?: annualPayoutsDate;
    monthlyDate?: number;
    endPayout?: balancePayoutEndType;
    endPayoutDate?: string;
    endPayoutAfter?: number;
  }

  export interface UpdatePayoutConfigResponse {
    payoutConfig: PayoutConfigRequest;
  }

  export type GetPayoutConfigResponse = UpdatePayoutConfigResponse;

  export interface UpdateLowLimitResponse {
    partner: Partner;
  }

  export enum FeatureFlags {
    PARTNERS_DASHBOARD_API_KEYS = 'partners_dashboard_api_keys',
    PARTNERS_DASHBOARD_WALLET_MANAGEMENT = 'partners_dashboard_wallet_management',
    PARTNERS_DASHBOARD_ENABLED_COUNTRIES = 'partners_dashboard_enabled_countries',
    PARTNERS_DASHBOARD_WHITELISTED_IPS = 'partners_dashboard_whitelisted_ips',
    PARTNERS_DASHBOARD_CHANNELS_COVERAGE = 'partners_dashboard_channels_coverage',
  }

  export interface GetProfileResponse {
    user: User;
    partner: Partner;
    featureFlags?: FeatureFlags[];
  }

  export interface AssignPartnerWalletAddressResponse {
    walletAddress: string;
  }

  export interface GetWalletAddressesResponse {
    addresses: Address[];
  }

  export interface GetSettlementWalletsResponse {
    wallets: PartnerWallet[];
  }

  export interface UpdateWalletStatusRequest {
    address: string;
    status: string;
    reason?: string;
  }

  export interface UpdateWalletStatusResponse {
    address: PartnerWallet;
  }

  export interface ProcessSettlementPayload {
    amount: number;
    sequenceId: string;
    source: 'dashboard';
    walletAddress: string;
    cryptoCurrency: string;
    cryptoNetwork: string;
  }

  export interface GetAllDataPayload {
    query: any;
    index: string;
    scroll_time: string;
    scroll_size: number;
    scroll_id?: string;
  }

  export interface UserRolePayload {
    email: string;
    role: string;
    name: string;
  }

  export interface UpdateUserRolePayload {
    userId: string;
    role: Role;
  }

  export interface UpdateUserRoleResponse {
    user: TeamMemberTypes.TeamMember;
  }

  export interface ResetUserPasswordPayload {
    userId: string;
  }

  export interface ConfirmPasswordResetPayload {
    confirmationCode: string;
    password: string;
    email: string;
  }

  enum ChannelTypes {
    P2P = 'p2p',
    EFT = 'eft',
    MOMO = 'momo',
    BANK = 'bank',
    CARD = 'card',
    YELLOW_CARD_PIN = 'yellowCardPin',
  }

  export interface Channel {
    id: string;
    vendorId?: string;
    vendorName?: string;
    rampType: 'withdraw' | 'deposit';
    channelType: ChannelTypes;
    country: string;
    currency: string;
    countryCurrency: string;
    status: Status;
    feeUSD: number;
    feeLocal: number;
    min: number;
    max: number;
    successThreshold: number;
    estimatedSettlementTime: number;
    settlementType: 'instant' | 'manual';
    createdAt: number;
    updatedAt: number;
    widgetStatus: Status;
    apiStatus: Status;
    widgetMin?: number;
    widgetMax?: number;
  }

  export interface GetChannelsResponse {
    channels: Channel[];
  }

  export interface SearchQuery {
    query: {
      bool: {
        should: any[];
        must: any[];
        filter: any[];
      };
    };
    from: number;
    size: number;
    sort: any[];
  }

  export enum AccountType {
    BANK = 'bank',
    MOMO = 'momo',
  }

  export interface Recipient {
    name?: string;
    country?: string;
  }

  export interface Destination {
    accountNumber: string;
    accountType: AccountType;
  }

  export enum STATUS {
    PENDING = 'pending',
    PROCESSING = 'processing',
    COMPLETE = 'complete',
    FAILED = 'failed',
  }

  export enum TRANSACTION_TYPE {
    WITHDRAWAL = 'payout',
    DEPOSIT = 'topup',
  }

  export enum NETWORK {
    BTC = 'BTC',
    ERC20 = 'ERC20',
    TRC20 = 'TRC20',
    XLM = 'XLM',
    CELO = 'CELO',
  }

  export enum COINS {
    USDC = 'USDC',
    USDT = 'USDT',
    CUSD = 'CUSD',
  }

  export enum PartnerRateStatus {
    ENABLED = 'enabled',
    DISABLED = 'disabled',
    PENDING_ENABLE = 'pending_enable',
    PENDING_DISABLE = 'pending_disable',
  }

  export interface PartnerRate {
    rateId: string;
    locale: string;
    code: string;
    buy: number;
    sell: number;
    status: string;
    id: string;
  }

  export interface UpdatePartnerRateStatusObject {
    status: string;
    platformRateId: string;
    partnerRateId?: string;
  }

  export interface UpdatePartnerRateStatusRequest {
    rates: UpdatePartnerRateStatusObject[];
  }

  export interface GetPartnerRatesResponse {
    rates: PartnerRate[];
  }

  export interface Collection {
    id: string;
    country: string;
    convertedAmount: number;
    status: string;
    source: Destination;
    createdAt: Date;
    currency: string;
    recipient: Recipient;
    amount: number;
    sequenceId: string;
    rate: number;
    sessionId: string;
  }

  export interface Payment {
    id: string;
    country: string;
    convertedAmount: number;
    status: string;
    createdAt: Date;
    currency: string;
    amount: number;
    sequenceId: string;
    rate: number;
    sessionId: string;
    destination: Destination;
    sender: Recipient;
  }

  export interface Settlement {
    id: string | any;
    status: STATUS;
    type: TRANSACTION_TYPE;
    transactionHash?: string;
    internalWalletAddress: string;
    cryptoCurrency: string;
    cryptoAmount: number | string;
    network?: NETWORK;
    externalWalletAddress?: string;
    fiatAmountUSD: string | number;
    cryptoRate: string | number;
    createdAt?: Date;
  }

  export interface GetDataResponse {
    data: Collection[] | Payment[] | Settlement[];
    total: number;
  }

  export interface GetDataPayload {
    query: SearchQuery;
    index: string;
  }

  export interface GetAllDataResponse {
    hits: Collection[] | Payment[] | Settlement[];
    scroll_id: string;
  }

  export interface CreateAPIKey {
    name: string;
    status: string;
    expiryDate?: number;
    permissions: string[];
  }

  export interface CreateAPIKeyResponse {
    apiKey: string;
    secretKey: string;
  }
}
