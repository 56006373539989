<template>
  <Dialog :open="showDialog" :width="416" @close-dialog="$emit('hideDialog')">
    <template v-slot:content>
      <div class="wallet-top-up-header">
        <div class="wallet-top-up-header-heading">
          {{ $t('balance.dialog.headingText') }}
        </div>
        <div class="wallet-top-up-header-subheading">
          Only send {{ selectedTopupCoin }} ( {{ selectedTopupNetwork }} ) to
          this wallet using its native blockchain to top up your balance
        </div>
        <div class="qrCode">
          <img
            v-if="walletAddress"
            :src="qrCodeUrl"
            :alt="walletAddress"
            height="180"
            width="185"
          />
        </div>
      </div>
      <div class="currency-network">
        <Dropdown
          :options="coinList"
          @update:selected="selectTopupCoin"
          :default-value="{
            label: selectedTopupCoin,
            icon: selectedTopupCoin,
            value: selectedTopupCoin,
          }"
          :key="selectedTopupCoin"
        />
        <DropDownList
          :options="networksList"
          @update:selected="selectTopupNetwork"
          :default-value="selectedTopupNetwork"
          :key="selectedTopupNetwork"
        />
      </div>
      <div class="wallet-top-up-address">
        <div>
          <div class="wallet-top-up-address-text">
            {{ $t('balance.dialog.walletAddress') }}
          </div>
          <div class="text-xs">
            {{ loading ? 'Loading...' : walletAddress ?? 'Address not found' }}
          </div>
        </div>
        <div>
          <img
            :src="require('@/assets/Copy.svg')"
            alt="Copy"
            class="cursor-pointer"
            @click.prevent="copy()"
            @keypress="copy()"
          />
        </div>
      </div>
      <div class="wallet-top-up-tag" v-if="addressTag">
        <div>
          <div class="text-xs brand-grey-500">
            {{ $t('balance.dialog.tag') }}
          </div>
          <div class="text-xs">
            {{ addressTag }}
          </div>
        </div>
        <div>
          <img
            :src="require('@/assets/Copy.svg')"
            alt="Copy"
            class="cursor-pointer"
            @click.prevent="copyTag()"
            @keypress="copyTag()"
          />
        </div>
      </div>
      <div class="wallet-top-up-info">
        <div>
          <img :src="require('@/assets/Warning.svg')" alt="Warning" />
        </div>
        <div class="ml-2">
          <div class="font-bold">
            Only send {{ selectedTopupCoin }} ( {{ selectedTopupNetwork }} ) to
            this address
          </div>
          <div class="text-xs mt-2">
            Sending any other digital asset through the
            {{ selectedTopupCoin }}
            ( {{ selectedTopupNetwork }} ) will result in permanent loss
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import {
  Dialog,
  Dropdown,
  DropDownList,
} from '@yellowcard/components-library-vue3';
import * as QRCode from 'qrcode';
import { useUiStore } from '@/store/modules/ui';
import { useWalletsStore } from '@/store/modules/wallets';
import { storeToRefs } from 'pinia';
import { currencyNetworkMap, supportedCoins } from '@/utils/helpers';

export default defineComponent({
  name: 'WalletTopUpModal',
  props: {
    copy: {
      type: Function,
      default: () => true,
    },
    copyTag: {
      type: Function,
      default: () => true,
    },
    showDialog: Boolean,
  },
  components: {
    Dialog,
    Dropdown,
    DropDownList,
  },
  setup() {
    const uiStore = useUiStore();
    const walletsStore = useWalletsStore();
    const {
      currencyNetworkSelect,
      selectTopupCoin,
      selectTopupNetwork,
      getSelectedTopupCoin,
    } = walletsStore;
    const {
      walletAddress,
      addressTag,
      currencyNetwork,
      networks,
      selectedTopupNetwork,
      selectedTopupCoin,
      coinList,
      networksList,
    } = storeToRefs(walletsStore);
    const { loading } = storeToRefs(uiStore);
    const qrCodeUrl = ref(undefined);
    const selectedCoin = ref();
    const selectedNetwork = ref<string>('');

    async function getQrCodeUrl(address: string) {
      if (!address) return;
      qrCodeUrl.value = await QRCode.toDataURL(address);
    }

    onMounted(async () => {
      selectedCoin.value = getSelectedTopupCoin();
      selectedNetwork.value = selectedTopupNetwork.value ?? 'TRC20';
      await getQrCodeUrl(walletAddress.value);
    });

    watch(walletAddress, async newAddress => {
      if (newAddress) {
        await getQrCodeUrl(newAddress);
      }
    });

    return {
      qrCodeUrl,
      walletAddress,
      currencyNetworkSelect,
      currencyNetworkMap,
      currencyNetwork,
      networks,
      loading,
      addressTag,
      selectedTopupNetwork,
      selectedTopupCoin,
      coinList,
      networksList,
      selectTopupCoin,
      selectTopupNetwork,
      supportedCoins,
      selectedCoin,
      selectedNetwork,
    };
  },
});
</script>

<style lang="scss">
@import '@/common/styles/base';

.wallet-top-up {
  &-header {
    margin-top: 3rem;
    text-align: center;
  }

  &-header-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  &-header-subheading {
    margin-bottom: 3rem;
    margin-top: 0.5rem;
  }

  &-address {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin: 0 5%;
    margin-bottom: 1.5rem;

    > div:first-child {
      width: 95%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > div:last-child {
      width: 5%;
      display: flex;
      align-items: flex-end;
    }
  }

  &-address-text {
    color: $brand-grey-500;
    font-size: 12px;
    line-height: 18px;
  }

  &-network {
    display: flex;
    justify-content: space-between;
  }

  &-tag {
    display: flex;
    justify-content: space-between;
    margin: 0 5%;
    margin-top: 1.5rem;

    > div:first-child {
      width: 95%;
    }

    > div:last-child {
      width: 5%;
      display: flex;
      align-items: flex-end;
    }
  }

  &-info {
    background-color: $brand-primary-yellow-25;
    border-radius: 0.375rem;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
  }
}

.currency-network {
  display: flex;
  justify-content: space-between;
  margin: 0 5%;
  margin-bottom: 25px;

  & .dropdown-list-container {
    width: 65%;

    > .select-list-body {
      width: 100%;
      height: 45px !important;

      > .vue-select {
        border-radius: 4px;
        border-color: $brand-grey-300 !important;
        color: $brand-grey-700 !important;
      }
    }
  }

  & .select-list-body {
    width: 30%;
    height: 45px;

    > .vue-select {
      height: 100%;
    }
  }
}

.qrCode {
  margin: -40px 0 0 0;
}

@include media('<tablet') {
  .no-border {
    border: 0px !important;
    padding: 0px !important;
  }
}
</style>
