<template>
  <Dialog :open="isOpen" :width="395" @close-dialog="$emit('closeDialog')">
    <template v-slot:content>
      <div class="review-countries-status dialog-modal-container">
        <div class="review-countries-status-logo">
          <img
            class="flex mx-auto mb-8"
            :src="require('@/assets/refreshedHumanFlag.svg')"
            alt="Yellowcard Logo"
          />
        </div>
        <div class="review-countries-status-header">
          {{ $t('reviewStatusChange.headingText') }}
        </div>
        <div class="review-countries-status-body">
          <div class="review-countries-status-body-header">
            <div>Country</div>
            <div>Action</div>
          </div>
          <div
            class="review-countries-status-body-row"
            v-for="rate in pendingChanges"
            :key="rate.id"
          >
            <div>{{ mapCountryCode(rate.locale) }} ( {{ rate.code }} )</div>
            <div>{{ titleCase(rate.action) }}</div>
          </div>
        </div>
        <div class="review-countries-status-footer">
          <Button
            @click="denyRequest"
            variant="outline"
            text="Deny"
            size="large"
            :radius="10"
            :disabled="denyButtonDisabled"
          />
          <Button
            @click="approveRequest"
            text="Approve"
            size="large"
            :radius="10"
            :disabled="approveButtonDisabled"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>
<script lang="ts">
import { Dialog, Button } from '@yellowcard/components-library-vue3';
import { useUiStore } from '@/store/modules/ui';
import { usePartnerStore } from '@/store/modules/partner';
import { defineComponent, ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { PortalServiceTypes } from '@/types/portalService';
import { mapCountryCode, titleCase } from '@/utils/helpers';

export default defineComponent({
  name: 'ChangeCountriesStatus',
  components: {
    Dialog,
    Button,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['closeDialog'],
  setup(_, { emit }) {
    const uiStore = useUiStore();
    const partnerStore = usePartnerStore();

    const { partnerRates } = storeToRefs(partnerStore);
    const pendingChanges = ref<any[]>([]);

    const approveButtonDisabled = ref(false);
    const denyButtonDisabled = ref(false);

    const approveRequest = async () => {
      try {
        approveButtonDisabled.value = true;
        const payload = pendingChanges.value.map(rate => ({
          partnerRateId: rate.id,
          platformRateId: rate.rateId,
          status: rate.newStatus,
        }));
        await partnerStore.reviewPartnerRateStatusChanges(payload);
        await partnerStore.fetchPartnerRates();
        uiStore.showInfoToast({ message: 'Request approved', duration: 3000 });
        approveButtonDisabled.value = false;
        emit('closeDialog');
      } catch (error) {
        approveButtonDisabled.value = false;
        console.log(error);
        uiStore.showToast({
          message: 'Error occured while approving changes',
          duration: 3000,
        });
      }
    };

    const denyRequest = async () => {
      try {
        denyButtonDisabled.value = true;
        const payload = pendingChanges.value.map(rate => ({
          status: rate.previousStatus,
          partnerRateId: rate.id,
          platformRateId: rate.rateId,
        }));
        await partnerStore.reviewPartnerRateStatusChanges(payload);
        await partnerStore.fetchPartnerRates();
        uiStore.showInfoToast({ message: 'Request denied', duration: 3000 });
        denyButtonDisabled.value = false;
        emit('closeDialog');
      } catch (error) {
        console.log(error);
        denyButtonDisabled.value = false;
        uiStore.showToast({
          message: 'Error occured while approving changes',
          duration: 3000,
        });
      }
    };

    const getAction = (status: string) => {
      switch (status) {
        case PortalServiceTypes.PartnerRateStatus.PENDING_DISABLE.toString():
          return 'Disable';
        case PortalServiceTypes.PartnerRateStatus.PENDING_ENABLE.toString():
          return 'Enable';
        default:
          return '';
      }
    };

    const getNewStatus = (status: string) => {
      switch (status) {
        case PortalServiceTypes.PartnerRateStatus.PENDING_DISABLE.toString():
          return PortalServiceTypes.PartnerRateStatus.DISABLED.toString();
        case PortalServiceTypes.PartnerRateStatus.PENDING_ENABLE.toString():
          return PortalServiceTypes.PartnerRateStatus.ENABLED.toString();
        default:
          return '';
      }
    };

    const getPreviousStatus = (status: string) => {
      switch (status) {
        case PortalServiceTypes.PartnerRateStatus.PENDING_DISABLE.toString():
          return PortalServiceTypes.PartnerRateStatus.ENABLED.toString();
        case PortalServiceTypes.PartnerRateStatus.PENDING_ENABLE.toString():
          return PortalServiceTypes.PartnerRateStatus.DISABLED.toString();
        default:
          return '';
      }
    };

    onMounted(() => {
      pendingChanges.value = partnerRates.value
        .filter(rate =>
          [
            PortalServiceTypes.PartnerRateStatus.PENDING_DISABLE.toString(),
            PortalServiceTypes.PartnerRateStatus.PENDING_ENABLE.toString(),
          ].includes(rate.status),
        )
        .map(rate => ({
          ...rate,
          previousStatus: getPreviousStatus(rate.status),
          action: getAction(rate.status),
          newStatus: getNewStatus(rate.status),
        }));
    });

    return {
      approveRequest,
      denyRequest,
      pendingChanges,
      mapCountryCode,
      titleCase,
      approveButtonDisabled,
      denyButtonDisabled,
    };
  },
});
</script>
<style lang="scss">
@import '@/common/styles/_base.scss';

.review-countries-status {
  margin: 3%;

  &-header {
    font-weight: 700;
    font-size: 24px;
    color: $brand-black;
    margin: 3% 0;
  }

  &-subheader {
    font-size: 16px;
    font-weight: 400;
    color: $brand-black;
  }

  &-body {
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    background-color: $brand-grey-50;
    margin: 5% 0;

    > div {
      display: flex;
      margin-top: 1%;
      margin-bottom: 1%;

      > div {
        width: 50%;

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }

    &-header {
      color: $brand-grey-600;
      font-weight: 500;
      font-size: 12px;
    }

    &-row {
      color: $brand-grey-700;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;

    > .yc--button {
      width: 45% !important;
    }
  }
}
</style>
